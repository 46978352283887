.priceBlob {
    position: absolute;
    right: 1.8rem;
    top: 1.5rem;
    background-color: #fffcd2;
    color: #121212;
    border: 3px solid #D4AF37;
    padding: 5px;
    border-radius: 10px;
    line-height: 0;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.priceBlob>diV {
    padding: 0 5px;
}

.componentCard {
    border-radius: 10;
    padding: 1rem;
    width: 16vw;
    max-width: 16vw;
    min-width: 235px;
    max-height: 420px;
    min-height: 420px;
    background-color: #D4AF37;
    color: #121212;
    box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.37);
}

.componentCard .card-title {
    font-size: 1.4rem;
    color: #121212;
}


.componentImage {
    object-fit: contain;
    height: 20vh;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 2vh;
}

