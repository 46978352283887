.navbar {
	padding: 0;
	height: 5rem;
	background: #D4AF37;
	display: flex;
	justify-content: flex-start;
	font-family: Montserrat;
	z-index: 1;
	border-bottom: 0.5rem solid #FFFCD2;
	box-shadow: 0px 4px 5px 5px rgba(0, 0, 0, 0.25);
}

.navbar-options {
	height: 5rem;
	font-size: x-large;
	font-weight: bold;
	padding: 1rem;
	display: flex;
	align-items: center;
}

.navbar-options img {
	height: 3rem;
}

.navbar-options a {
	text-decoration: none;
	color: #121212;
}

.active {
	background: #FFFCD2;
}

.team {
	position: absolute;
	right: 0;
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	justify-content: flex-start;
}

.navbar-logout {
	width: 100%;
	padding: 0.5rem;
	text-align: center;
	background: linear-gradient(150deg, #bf5234, #992800);
	cursor: pointer;
	font-weight: bold;
	color: #FFFCD2;
	border-radius: 0 0 0.5rem 0.5rem;
}

.inv {
	display: none;
}


.logo-navbar {
	font-family: "Overseer", sans-serif;
	top: 5%;
	/* 5% da altura da janela */
	left: 5%;
	/* 5% da largura da janela */
	font-size: 3.5rem;
	color: #000000;
}

@font-face {
	font-family: "Overseer";
	src: url("../images/fonts/Overseer\ Italic.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}