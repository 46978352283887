html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  scrollbar-color: #d4af37;
}

* {
  scrollbar-color: #d4af37;
  scrollbar-width: none;
}

.team-page-container {
  height: 100vh;
  padding-bottom: 40px;
  background-color: #01002b;
  color: #d4af37;
  /* Set your background color */
  position: relative;
}

.create-team-section,
.join-team-section {
  border: 1px solid #d4af37;
  border-radius: 20px;
  background-color: #d4af37;
  height: 60vh;
  overflow: hidden;
  overflow-y: auto;
}

.scrollable-list {
  max-height: 40vh;
  overflow-y: auto;
  background-color: #d4af37;
}

.team-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-item.active {
  background-color: #01002b;
  /* Change background for active selection */
}

.button1 {
  background-color: #01002b;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

.logo-register-page {
  font-family: "Overseer", sans-serif;
  margin-top: 20px;
  margin-left: 10px;
  color: #d4af37;
  top: 5%;
  /* 5% da altura da janela */
  left: 5%;
  /* 5% da largura da janela */
  font-size: 4rem;
}

@font-face {
  font-family: "Overseer";
  src: url("../images/fonts/Overseer\ Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
