.page_container {
  background-color: #01002b !important;
  margin: 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
}

.edition {
  margin-bottom: 30px;
  margin-top: -30px;
  margin-left: 45%;
  color: white;
  text-align: right;
  font-weight: bold;
  font-size: 2rem;
}

.container button {
  width: 18rem;
  background-color: #d4af37;
  border-radius: 5px;
  border-width: 2px;
  border-color: #ffaf00;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: normal;
}

.container button:hover {
  background: linear-gradient(150deg, #e17a5d, #992800);
  border-color: #ffaf00;
}

.horizontal_stripes {
  width: 100%;
  margin-top: 20px;
  position: absolute;
}

.logo-login-page {
  font-family: "Overseer", sans-serif;
  margin-top: 17vh;
  font-size: 11rem;
  color: #d4af37;
}

@font-face {
  font-family: "Overseer";
  src: url("../images/fonts/Overseer\ Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.little-images {
  width: 25%;
  height: auto;
}

.image-shift-1 {
  margin-top: 10px;
}

.image-shift-2 {
  margin-top: 20px;
}

.image-above {
  position: relative;
  top: -30px;
  z-index: 1;
}
