.login-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  background: transparent;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}

.footer {
  width: 100%;
  height: 4rem;
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  background: #d4af37;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 0px -4px 5px 5px rgba(0, 0, 0, 0.25);
}

.footer-partners {
  width: 25%;
  padding: 0.1rem 0.5rem;
  display: flex;
  align-items: center;
  font-family: Montserrat;
}

.footer-partners a {
  font-size: 2rem;
  font-weight: normal;
  text-decoration: none;
  color: #ffffff;
  /* As Imagens do Footer têm que ser trocadas pelas novas cores */
}

.footer-neec {
  width: 25%;
  padding: 0.1rem 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-footer-bar {
  width: 100%;
  height: 2rem;
  background: #d4af37;
}

.footer-icons {
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  align-self: center;
}

.footer-icons img {
  width: 2.5rem;
}
