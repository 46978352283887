.schedule {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.schedule-card {
	height: 40rem;
	background-color: #88B2A9;
	margin: 5rem;
	border-radius: 1rem;
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	/* drop-shadow: 0px -4px 5px 5px rgba(0, 0, 0, 0.25); */
}

.side-stripes {
	padding-left: 1rem;
	height: 100%;
}

.side-stripes img {
	width: 7rem;
	height: 100%;
	object-fit: cover;
}
