.evaluation-main-container {
	overflow: auto;
    display: flex;
    justify-content: center;
}

.evaluation-card {
    height: 70vh;
	background-color: #88B2A9;
	border-radius: 1rem;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	margin: 2rem;
	padding: 2rem;
	display: flex;
	justify-content: flex-start;
	font-family: 'Montserrat';
    overflow: scroll;
    width: fit-content;
}

.evaluation-card img {

    height: 100%;
}