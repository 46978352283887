.mentors-main-container {
	overflow: auto;
	display: flex;
	justify-content: flex-start;
	overflow: scroll;
	background-color: #01002B;
	height: 80vh;
}

.mentor-card {
	width: 60rem;
	background-color: #01002B;
	border-radius: 1rem;
	margin: 2rem;
	padding: 2rem;
	flex-grow: 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	font-family: 'Montserrat';
	overflow: scroll;
	height: 78vh;
}

.mentor-card h2 {
	font-size: 2.5rem;
	font-weight: bold;
	color: #ffffff;
}

.mentor-card-body {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #01002B;
}

.mentor-card-info {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.mentor-card-body p {
	font-size: 1.5rem;
	font-weight: normal;
	color: white;
	margin: 1rem;
}

.mentor-card-icons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.mentor-card-icons svg {
	margin: 0.5rem;
}

.mentor-card-notify {
	cursor: pointer;
}

.mentor-descriptions {
	flex-grow: 1;
	padding: 2rem;
}

.mentor-descriptions h2 {
	color: #ffffff;
	font-weight: bold;
}

.mentor-descriptions p {
	color: #ffffff;
	font-weight: normal;
	font-size: 1.2rem;
}