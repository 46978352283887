.leftCard {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-block: 0vw;
    padding-left: 2vw;
    border-Radius: 12pt;
    width: 100%;
    height: 30vh;
    max-height: 30vh;
}

.linhas1 {
    display: flex;
    width: 55vw;
    align-items: center;
    justify-content: space-between;
    padding-top: 2vh;
}

.linhas2 {
    display: flex;
    width: 55vw;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 2vh;
}

.profilePicture {
    max-height: 30vh;
    object-fit: contain;
    background-color: rgb(9, 1, 59);
    background: linear-gradient(180deg, rgba(9, 1, 59, 1) 51%, rgba(54, 36, 98, 1) 100%);
    border-radius: 12px;
    border: 2px solid white;
}

.projectCard {
    background: rgb(9 1 59 / 0.4);
    border-radius: 12px;
    padding: 1rem;
    color: white;
    border: 2px solid white;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-content {
    background: linear-gradient(180deg, rgba(9, 1, 59, 1) 51%, rgba(54, 36, 98, 1) 100%);
}

.projectCard * p {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 14vh;
}


.projectTitle {
    color: #D4AF37;
}

.teamTitle {
    color: #5B0A91;
}


.budgetTitle {
    font-size: medium;
    color: color;
}

.teamGalleryBox {
    margin-block: 15px;
    background: rgb(9 1 59 / 0.4);
    border-radius: 12px;
    padding: 1rem;
    color: white;
    border: 2px solid white;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0 12px 1px #5B0A91;
}

.changeProject {
    background-color: transparent;
    border: 3px solid white;
    border-radius: 9px;
    font-size: larger;
    color: white;
    width: 100%;
}

.teamMembersCard {
    width: 100%;
    max-height: 30vh;
    overflow: auto;
    margin-block: 15px calc(15px + 0.5rem);
}

.teamMembersCard li {
    background-color: #0e0b22 !important;
    color: white !important;
    border-bottom: 1px solid white;
    font-size: larger;
}

.logsCard {
    width: 100%;
    max-height: 30vh;
    margin-block: 15px calc(15px + 0.5rem);
    background-color: #0e0b22 !important;
}

.logsCard li {
    background-color: #0e0b22 !important;
    color: white !important;
    border-bottom: 1px solid white;
    font-size: larger;
}

.galleryCard {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.budgetBox {
    display: flex;
    width: fit-content;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem;
    border-radius: 12px;
    border: 2px solid #D4AF37;
    align-self: end;
}

.budgetBox h3 {
    margin-right: 10px;
}

.budgetBox div {
    margin-right: 10px;
    font-size: x-large;
}

.popover {
    background-color: rgb(9, 1, 59);
    background: linear-gradient(180deg, rgba(9, 1, 59, 1) 51%, rgba(54, 36, 98, 1) 100%);
    transition: 0.5s;
}

.popover-body {
    color: #D4AF37;
}

.popover-header {
    color: #D4AF37;
    background-color: #0e0b22;
}

.popover img {
    border-radius: 5px;
}

.teamMembersCard.card>div li>a {
    color: white !important;
}

.adminTools {
    position: absolute;
    right: 0;
    top: 40%;
    background-color: #f48ee5;
    width: 20vw;
    padding: 15px;
    border-radius: 12px;
    transform: translateX(80%);
    transition: transform 0.5s;
}

.adminTools:hover {
    transform: translateX(0%);
}


.modalOver:empty {
    opacity: 0;
    transition: opacity 3s ease;
}

.modalOver {
    opacity: 1;
    transition: opacity 3s ease;
}
