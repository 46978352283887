.horario {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .horario .overlap-wrapper {
    background-color: #ffffff;
    height: 980px;
    overflow: hidden;
    width: 1920px;
  }
  
  .horario .overlap {
    height: 980px;
    position: relative;
    width: 1928px;
  }
  
  .horario .rectangle {
    background-color: #3c7f72;
    height: 980px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1920px;
  }
  
  .horario .group {
    background-color: #88b2a9;
    box-shadow: 0px -4px 5px 5px #00000040;
    height: 65px;
    left: 0;
    position: absolute;
    top: 915px;
    width: 1920px;
  }
  
  .horario .image {
    height: 50px;
    left: 1519px;
    object-fit: cover;
    position: absolute;
    top: 8px;
    width: 396px;
  }
  
  .horario .div {
    height: 49px;
    left: 798px;
    position: absolute;
    top: 9px;
    width: 350px;
  }
  
  .horario .vector {
    height: 49px;
    left: 0;
    position: absolute;
    top: 0;
    width: 49px;
  }
  
  .horario .img {
    height: 49px;
    left: 101px;
    position: absolute;
    top: 0;
    width: 49px;
  }
  
  .horario .vector-2 {
    height: 49px;
    left: 202px;
    position: absolute;
    top: 0;
    width: 49px;
  }
  
  .horario .vector-3 {
    height: 49px;
    left: 301px;
    position: absolute;
    top: 0;
    width: 49px;
  }
  
  .horario .team-box-instance {
    left: 34px !important;
    top: 127px !important;
  }
  
  .horario .team-box-2 {
    height: 853px;
    left: 977px;
    position: absolute;
    top: 127px;
    width: 943px;
  }
  
  .horario .header {
    background-color: transparent;
    height: 85px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1928px;
  }
  
  .horario .navbar {
    background-color: #88b2a9;
    box-shadow: 0px 4px 5px 5px #00000040;
    height: 85px;
    position: relative;
    width: 1920px;
  }
  
  .horario .text-wrapper {
    color: #ffffff;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 32px;
    font-weight: 600;
    left: 711px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 23px;
    width: 136px;
  }
  
  .horario .text-wrapper-2 {
    color: #ffffff;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 32px;
    font-weight: 600;
    left: 393px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 23px;
  }
  
  .horario .text-wrapper-3 {
    color: #ffffff;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 32px;
    font-weight: 600;
    left: 542px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 23px;
  }
  
  .horario .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 32px;
    font-weight: 600;
    left: 1610px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 23px;
  }
  
  .horario .semslogan {
    height: 55px;
    left: 15px;
    object-fit: cover;
    position: absolute;
    top: 15px;
    width: 226px;
  }
 
  